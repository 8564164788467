/* extending custom utilities on top on bootstrap*/

:root {
  --text: #000;
  --white: #fff;

  --bg: #f1f3f7;
  --text: #0b1626;
  --textTinted: #898b9b;
  --textTinted1: #828497;
  --white: #ffffff;
  --grayButton: rgba(11, 22, 38, 0.05);
  --sideBarShadow: 1px 0px 0px rgba(0, 0, 0, 0.08);
  --searchBg: rgba(11, 22, 38, 0.03);
  --minimizeNavWidth: 70px;
  --transparent: transparent;
  --checkboxBg: #6bcad3;
  --sidebarBg: #f1f3f7;
  --activeSidebarIconColor: #0b1626;
  --linkhoverbg: rgba(11, 22, 38, 0.07);
  --subtitleColor: rgba(0, 0, 0, 0.3);
  --blueGray: #60b6bf;
  --text-lighter: rgba(0, 0, 0, 0.8);
  --inputBg: rgba(0, 0, 0, 0.04);
  --tabColor: rgba(0, 0, 0, 0.5);
  --stepText: rgba(11, 22, 38, 0.6);
  --bg: #0b1626;
  --text: white;
  --activeSidebarIconColor: white;
  --searchBg: rgba(255, 255, 255, 0.05);
  --linkhoverbg: rgba(255, 255, 255, 0.1);
}

video::-webkit-media-controls-panel {
  /* Your styling here */
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2)) !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.break-word {
  /* line-break: anywhere; */
  word-wrap: break-word;
}

.btn-primary:is(.btn-primary:disabled, :active) {
  border: #f2b420 !important;
  background-color: #f2b420 !important;
  color: black !important;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
div,
b {
  word-break: break-word !important;
}

#about-me p,
#about-me span,
#about-me h1,
#about-me h2,
#about-me h3,
#about-me h4,
#about-me h5,
#about-me h6,
#about-me strong,
#about-me div,
#about-me b {
  font-size: 1.125rem !important;
}

.emoji-parent-container {
  position: absolute;
  bottom: 2rem;
  right: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.none {
  display: none;
}

.emoji-wrapper input {
  visibility: unset !important;
  font-size: 15px;
  padding-left: 2.2rem !important;
}

.common-phone-number-input input {
  font-size: 14px !important;
  border: none;
  outline: none;
  padding-left: 1rem;
}

.common-phone-number-input .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  width: 16px !important;
  height: 11px !important;
  border: none;
  box-shadow: unset !important;
}

.common-phone-number-input {
  padding-bottom: 0.6rem;
}

.PhoneInputCountrySelect option {
  font-size: 14px;
  color: #252525;
}

.shimmer-loading {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #f6f6f6 8%, #ddd 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

.wsc-contenteditable-mirror>div,
.wsc-contenteditable-mirror+div {
  padding-top: 0px !important;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: grey;
  pointer-events: none;
  display: block;
  /* For older browsers */
}

.chat-user-list {
  .overlay-text {
    font-size: 12px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}