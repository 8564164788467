.not-main-wrapper {
  position: relative;
}

.notification-box {
  width: 250px;
  height: 300px;
  transform: translate(-50%, 0);
  background-color: white;
  border: 1px solid #f2b420;
  position: absolute;
  top: 68px;
  left: 50%;
  border-radius: 5px;
  z-index: 100;
  opacity: 1;
  transition: 0.1s;
  pointer-events: all;
  user-select: none;
}

.bell-wrap {
  cursor: pointer;
}

.bell-wrap .not-badge {
  position: absolute;
  top: -8px;
  right: 12px;
  background: #f2b420;
  color: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.clear-all-button {
  position: sticky;
  top: 0;
  width: calc(100% - 1rem);
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: #f2b420;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.clear-all-button:hover {
  background-color: #e5a91f;
}

@media (max-width: 768px) {
  .notification-box {
    transform: translate(-10%, 0);
    top: 40px;
    left: 30%;
  }
}

.notification-box.hide {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.notification-content {
  width: 95%;
  height: calc(100% - 0.5rem);
  margin: 0.5rem;
  position: relative;
  overflow: auto;
}

.notification-content-with-button {
  height: calc(100% - 4rem);
}

.no-notifications {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #525252;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.not-title {
  font-size: 14px;
  text-align: left;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid #ececec94;
}

.not-title:hover {
  border-radius: 10px;
  background-color: #f2b32033;
}

.not-title.bold {
  font-weight: 500;
}

.notification-box-close-icon {
  position: fixed;
  border: 1px solid rgba(242, 179, 32, 1);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 4px;
  right: 10px;
  top: 10px;
  background: white;
  z-index: 100;
  cursor: pointer;
}

.not-icon {
  cursor: pointer;
}
